import React, { useRef } from 'react';
import { useInView } from 'framer-motion';

export default function Scale({ children, duration, once, rotate }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: once });

  return (
    <section ref={ref}>
      <div
        style={{
          transform: isInView
            ? `scale(1) ${rotate && 'rotate(0deg)'}`
            : `scale(0) ${rotate && 'rotate(180deg)'}`,
          opacity: isInView ? 1 : 0,
          transition: `all ${duration}s cubic-bezier(0.02, 0.55, 0.55, 1) 0.5s`,
        }}
      >
        {children}
      </div>
    </section>
  );
}
