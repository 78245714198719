import React from "react";
import logo from "../../assets/images/logo.png";
import MessageChat from "../../assets/icon/MessageChat.svg";
import Twitter from "../../assets/icon/twitter.svg";
import LinkedIn from "../../assets/icon/linkedin.svg";
import Facebook from "../../assets/icon/facebook.svg";
import Instagram from "../../assets/icon/instagram.svg";

const navData = [
  { label: "Solutions", path: "/" },
  { label: "Mitra Kartini", path: "/" },
  { label: "Company", path: "/" },
  { label: "Resources", path: "/" },
];

const FooterData = [
  { label: "About Us", path: "/", type: "Company" },
  { label: "Careers", path: "/", type: "Company" },
  { label: "Articles", path: "/", type: "Company" },
  { label: "Kartini x", path: "/", type: "Company" },
  { label: "Infrastructure & Security", path: "/", type: "Company" },
  { label: "Case Studies", path: "/", type: "Resources" },
  { label: "Use Cases", path: "/", type: "Resources" },
  { label: "Blogs", path: "/", type: "Resources" },
  { label: "Studies", path: "/", type: "Resources" },
  { label: "FAQ", path: "/", type: "Resources" },
];

const SocialMedia = [
  { icon: Facebook, path: "/" },
  { icon: Instagram, path: "/" },
  { icon: LinkedIn, path: "/" },
  { icon: Twitter, path: "/" },
];

const DefaultLayout = ({ children }) => {
  return (
    <div>
      <div className="sticky top-0 w-full z-20 transition-colors duration-500">
        <div className="py-3 px-4 md:px-0 bg-gradient-to-br from-blue-600 from-5% via-purple-600 via-50% to-soft-red-600 to-95%">
          <div className="max-w-6xl mx-auto flex justify-between">
            <p className="text-monochrome-0 md:block hidden">
              Conversations AI is changing the world
            </p>
            <div className="flex text-monochrome-0 gap-2">
              <a href="/">Sign in</a>
              <div className="flex">
                <a href="/">English</a>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <nav className="max-w-6xl mx-auto text-dark flex py-4 px-3 md:px-0 items-center justify-between  sticky top-0 w-full z-20 transition-colors duration-500">
            <div>
              <a href="/">
                <img src={logo} alt="logo" className="w-[110px]" />
              </a>
            </div>
            <div className="md:flex hidden items-center gap-4 ">
              <a
                href="/"
                className="bg-gradient-to-r from-blue-600 from-5% via-purple-500 via-50% to-soft-red-500 to-95% text-transparent bg-clip-text"
              >
                Conversation that Converts
              </a>
              {navData.map((item) => (
                <a href={item.path} className="text-monochrome-400 hover:text-monochrome-600">
                  {item.label}
                </a>
              ))}
            </div>
            <div>
              <button className="bg-gradient-to-br from-blue-100 to-soft-red-100 py-3 px-4 rounded-xl flex items-center gap-2">
                <img src={MessageChat} alt="icon" />
                <p className="bg-gradient-to-r from-blue-600 from-5% via-purple-600 via-50% to-soft-red-500 to-95% text-transparent bg-clip-text font-semibold">
                  Talk to an expert
                </p>
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div className="margin-min">{children}</div>
      <div className="border-t border-t-monochrome-100">
        <div className="max-w-6xl mx-auto grid md:grid-cols-4 grid-cols-1 gap-3 px-4 md:px-0 py-10 gap-y-7 md:gap-y-0">
          <div className="flex flex-col gap-4">
            <img src={logo} alt="logo" className="w-1/2" />
            <p className="text-monochrome-300 text-xs">
              Lorem ipsum dolor sit amet consectetur. Nulla etiam montes nulla odio congue nunc.
              Nibh quis mi lacinia sed euismod.
            </p>
            <div>
              <button className="bg-gradient-to-br from-blue-100 to-soft-red-100 py-3 px-4 rounded-xl flex items-center gap-2">
                <p className="bg-gradient-to-r from-blue-600 from-5% via-purple-600 via-50% to-soft-red-500 to-95% text-transparent bg-clip-text font-semibold">
                  Get in Touch
                </p>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg">Company</p>
            {FooterData.filter((item) => item.type === "Company").map((item, index) => (
              <div key={index}>
                <a href={item.path} className="text-monochrome-400">
                  {item.label}
                </a>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg">Resources</p>
            {FooterData.filter((item) => item.type === "Resources").map((item, index) => (
              <div key={index}>
                <a href={item.path} className="text-monochrome-400">
                  {item.label}
                </a>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg">Follow Us</p>
            <div className="flex gap-3 items-center">
              {SocialMedia.map((item, index) => (
                <div key={index}>
                  <a href={item.path} target="_blank" rel="noopener noreferrer">
                    <img src={item.icon} alt="icon" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-br from-blue-50 to-soft-red-50 px-4 md:px-0">
        <div className="flex max-w-6xl mx-auto justify-between py-3 md:text-sm text-xs">
          <div className="flex gap-3 text-monochrome-300">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/">Cookies</a>
            <a href="/">Contact Us</a>
          </div>
          <p className="text-monochrome-300">Copyright © 2024 PT. Kartini Otomasi Indonesia</p>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
