import Axios from "axios";

export const Get = async (url, params) => {
  let stringParams = "";
  // const token = Cookies.get('token');

  // if (!!token) Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  if (Object.keys(params).length !== 0) {
    let paramsArr = [];
    Object.keys(params).forEach((key) => {
      paramsArr.push(`${key}=${params[key]}`);
    });

    let paramJoin = paramsArr.join("&");

    stringParams = `?${paramJoin}`;
  }

  return new Promise((resolve, reject) => {
    // const token = Cookies.get('token');

    // if (!!token) Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    Axios.get(`${process.env.REACT_APP_BASE_API_URL}${url}${stringParams}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(async (res) => {
        if (res.data !== undefined) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        // if (err.response.status == 401) {
        //   Cookies.remove('token');
        //   Cookies.remove('user_data');
        //   setTimeout(() => {
        //     router.push('/login');
        //   }, 1000);
        // }

        reject(err);
      });
  });
};

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return isNaN(str);
}
