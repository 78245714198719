import React from "react";
import DefaultLayout from "../../Components/DefaultLayout";
import { useLocation } from "react-router-dom";

const Study = () => {
  const { state } = useLocation();
  const { title, image, text } = state ?? {};
  return (
    <DefaultLayout>
      <div className="min-h-svh max-w-6xl mx-auto py-5">
        <div className="grid grid-cols-3 gap-10">
          <img src={image} alt="" className="w-full object-cover rounded-xl" />
          <div className="col-span-2">
            <h2 className="text-4xl font-semibold">{title}</h2>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Study;
