import React from 'react';
import DefaultLayout from '../../Components/DefaultLayout';

const PrivacyPolicy = () => {
  return (
    <DefaultLayout>
      <div className='max-w-6xl mx-auto py-12 leading-loose'>
        <h2 className='text-4xl font-semibold'>Privacy Policy</h2>
        <h2 className='text-xl font-semibold'>Interpretation and Definitions</h2>
        <h3 className='text-xl font-semibold'>
          <strong>Interpretation</strong>
        </h3>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </p>

        <br />
        <p className='text-xl font-semibold'>
          <strong>Definitions</strong>
        </p>
        <p>
          <strong>“Account”</strong> means a unique account created for You to access our Service or
          parts of our Service.
        </p>
        <br />
        <p>
          <strong>“Business”</strong>, refers to the Company as the legal entity that collects
          Consumers’ personal information and determines the purposes and means of the processing of
          Consumers’ personal information, or on behalf of which such information is collected and
          that alone, or jointly with others, determines the purposes and means of the processing of
          consumers’ personal information, that does business in the Republic of Indonesia
        </p>
        <br />
        <p>
          <strong>“Company”</strong> (referred to as either “the Company”, “We”, “Us” or “Our” in
          this Agreement) refers to PT. Kartini Otomasi Indonesia.
        </p>
        <br />
        <p>
          <strong>“Country”</strong> refers to Indonesia
        </p>
        <br />
        <p>
          <strong>“Consumer”</strong>, A resident, as defined in the law, includes (1) every
          individual who is in the Republic of Indonesia for other than a temporary or transitory
          purpose, and (2) every individual who is domiciled in the Republic of Indonesia who is
          outside the Republic of Indonesia for a temporary or transitory purpose.
        </p>
        <br />
        <p>
          <strong>“Client”</strong> is the recipient of the Service provided by the Company
        </p>
        <br />
        <p>
          <strong>“Data Controller”</strong>, for the purposes of the GDPR (General Data Protection
          Regulation), refers to the Company as the legal person which alone or jointly with others
          determines the purposes and means of the processing of Personal Data.
        </p>
        <br />
        <p>
          <strong>“Device”</strong> means any device that can access the Service such as a computer,
          a cell phone or a digital tablet.
        </p>
        <br />
        <p>
          <strong>“Personal Data”</strong> is any information that relates to an identified or
          identifiable individual. For the purposes of GDPR, Personal Data means any information
          relating to You such as a name, an identification number, location data, online identifier
          or to one or more factors specific to the physical, physiological, genetic, mental,
          economic, cultural or social identity.
        </p>
        <br />
        <p>
          <strong>“Service”</strong> refers to the chatbot system or platform provided by the
          Company for its Client
        </p>
        <p>
          *“Service Provider” *means any natural or legal person who processes the data on behalf of
          the Company. It refers to third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing how the Service is
          used. For the purpose of the GDPR, Service Providers are considered Data Processors.
        </p>
        <br />
        <p>
          <strong>“Usage Data”</strong> refers to data collected automatically, either generated by
          the use of the Service or from the Service infrastructure itself (for example, the mobile
          number collected through chatbot conversations).
        </p>
        <br />
        <p>
          <strong>“You”</strong> means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is accessing or using
          the Service, as applicable. Under GDPR (General Data Protection Regulation), You can be
          referred to as the Data Subject or as the User as you are the individual using the
          Service.
        </p>
        <h2 className='text-2xl font-semibold'>Collecting and Using Your Personal Data</h2>
        <br />
        <p>
          <strong>Personal Data</strong>
        </p>

        <p>
          While using Our Service, We may ask You to provide Us with certain personally identifiable
          information that can be used to contact or identify You. Personally identifiable
          information may include, but is not limited to:
        </p>
        <p>- Email address</p>
        <p>- First name and last name</p>
        <p>- Phone number</p>
        <p>- Address, State, Province, ZIP/Postal code, City</p>
        <p>- Usage Data</p>
        <p>- Gender</p>
        <p>- Age</p>
        <p>- Date Of Birth</p>
        <p>- Usage Data</p>
        <p>- Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Your Device’s Internet Protocol address (e.g.
          IP address), browser type, browser version, the pages of our Service that You visit, the
          time and date of Your visit, the time spent on those pages, unique device identifiers,
          mobile number for messaging platforms and other diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may collect certain
          information automatically, including, but not limited to, the type of mobile device You
          use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
          operating system, the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You visit our Service or
          when You access the Service by or through a mobile device.
        </p>

        <br />
        <p>
          <strong>Tracking Technologies and Cookies</strong>
        </p>
        <p>
          We use Cookies and similar tracking technologies to track the activity on Our Service and
          store certain information. Tracking technologies used are beacons, tags, and scripts to
          collect and track information and to improve and analyze Our Service. The technologies We
          use may include:
        </p>

        <br />
        <p>
          <strong>Cookies or Browser Cookies</strong>
        </p>
        <p>
          A cookie is a small file placed on Your Device. You can instruct Your browser to refuse
          all Cookies or to indicate when a Cookie is being sent. However, if You do not accept
          Cookies, You may not be able to use some parts of our Service. Unless you have adjusted
          Your browser setting so that it will refuse Cookies, our Service may use Cookies.
        </p>

        <br />
        <p>
          <strong>Web Beacons</strong>
        </p>
        <p>
          Certain sections of our Service and our emails may contain small electronic files known as
          web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
          permit the Company, for example, to count users who have visited those pages or opened an
          email and for other related website statistics (for example, recording the popularity of a
          certain section and verifying system and server integrity).
        </p>
        <p>
          Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on Your
          personal computer or mobile device when You go offline, while Session Cookies are deleted
          as soon as You close Your web browser.
        </p>
        <p>We use both Session and Persistent Cookies for the purposes set out below:</p>

        <br />
        <p>
          <strong>Necessary / Essential Cookies</strong>
        </p>
        <p>Type: Session Cookies</p>
        <p>Administered by: Us</p>
        <p>
          Purpose: These Cookies are essential to provide You with services available through the
          Website and to enable You to use some of its features. They help to authenticate users and
          prevent fraudulent use of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide You with those
          services.
        </p>
        <p>We use both Session and Persistent Cookies for the purposes set out below:</p>

        <br />
        <p>
          <strong>Necessary / Essential Cookies</strong>
        </p>
        <p>Type: Session Cookies</p>
        <p>Administered by: Us</p>
        <p>
          Purpose: These Cookies are essential to provide You with services available through the
          Website and to enable You to use some of its features. They help to authenticate users and
          prevent fraudulent use of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide You with those
          services.
        </p>

        <br />
        <p>
          <strong>Functionality Cookies</strong>
        </p>
        <p>Type: Persistent Cookies</p>
        <p>Administered by: Us</p>
        <p>
          Purpose: These Cookies allow us to remember choices You make when You use the Website,
          such as remembering your login details or language preference. The purpose of these
          Cookies is to provide You with a more personal experience and to avoid You having to
          re-enter your preferences every time You use the Website.
        </p>

        <br />
        <p>
          <strong>Tracking and Performance Cookies</strong>
        </p>
        <p>Type: Persistent Cookies</p>
        <p>Administered by: Third-Parties</p>
        <p>
          Purpose: These Cookies are used to track information about traffic to the Website and how
          users use the Website. The information gathered via these Cookies may directly or
          indirectly identify you as an individual visitor. This is because the information
          collected is typically linked to a pseudonymous identifier associated with the device you
          use to access the Website. We may also use these Cookies to test new pages, features or
          new functionality of the Website to see how our users react to them.
        </p>
        <p>
          For more information about the cookies we use and your choices regarding cookies, please
          visit our Cookies Policy or the Cookies section of our Privacy Policy.
        </p>

        <br />
        <p>
          <strong>
            Use of Your Personal Data<span class='ql-cursor'>﻿</span>
          </strong>
        </p>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul className='list-disc ml-4'>
          <li>
            <strong>To provide and maintain our Service</strong>, including to monitor the usage of
            our Service.
          </li>
          <li>
            <strong>To manage Your Account</strong>: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to different functionalities
            of the Service that are available to You as a registered user.
          </li>
          <li>
            <strong>For the performance of a contract</strong>: the development, compliance and
            undertaking of the purchase contract for the products, items or services You have
            purchased or of any other contract with Us through the Service.
          </li>
          <li>
            <strong>To contact You</strong>: To contact You by email, telephone calls, SMS, or other
            equivalent forms of electronic communication, such as a mobile application’s push
            notifications regarding updates or informative communications related to the
            functionalities, products or contracted services, including the security updates, when
            necessary or reasonable for their implementation.
          </li>
          <li>
            <strong>To provide You</strong> with news, special offers and general information about
            other goods, services and events which we offer that are similar to those that you have
            already purchased or enquired about unless You have opted not to receive such
            information.
          </li>
          <li>
            <strong>To manage Your requests</strong>: To attend and manage Your requests to Us.
          </li>
          <li>
            <strong>For business transfers</strong>: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale
            or transfer of some or all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about
            our Service users is among the assets transferred.
          </li>
          <li>
            <strong>For other purposes</strong>: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the effectiveness of our
            promotional campaigns and to evaluate and improve our Service, products, services,
            marketing and your experience.
          </li>
        </ul>
        <br />
        <p>
          <strong>We may share Your personal information in the following situations:</strong>
        </p>
        <ul className='list-disc ml-6'>
          <li>
            <strong>With Service Providers</strong>: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, for payment processing,
            to contact You.
          </li>
          <li>
            <strong>For our Client</strong>: We share Your personal information to our client as a
            method of reporting our services for them
          </li>
          <li>
            <strong>For business transfers</strong>: We may share or transfer Your personal
            information in connection with, or during negotiations of, any merger, sale of Company
            assets, financing, or acquisition of all or a portion of Our business to another
            company.
          </li>
          <li>
            <strong>With Affiliates</strong>: We may share Your information with Our affiliates, in
            which case we will require those affiliates to honor this Privacy Policy. Affiliates
            include Our parent company and any other subsidiaries, joint venture partners or other
            companies that We control or that are under common control with Us.
          </li>
          <li>
            <strong>With business partners</strong>: We may share Your information with Our business
            partners to offer You certain products, services or promotions.
          </li>
          <li>
            <strong>With other users</strong>: when You share personal information or otherwise
            interact in the public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside.
          </li>
          <li>
            <strong>With Your consent</strong>: We may disclose Your personal information for any
            other purpose with Your consent.
          </li>
        </ul>
        <br />
        <p>
          <strong>Retention of Your Personal Data</strong>
        </p>
        <p>
          The Company will retain Your Personal Data only for as long as is necessary for the
          purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the
          extent necessary to comply with our legal obligations (for example, if we are required to
          retain your data to comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data is used to
          strengthen the security or to improve the functionality of Our Service, or We are legally
          obligated to retain this data for longer time periods.
        </p>
        <br />
        <p>
          <strong>Transfer of Your Personal Data</strong>
        </p>
        <p>
          Your information, including Personal Data, is processed at the Company’s operating offices
          and in any other places where the parties involved in the processing are located. It means
          that this information may be transferred to — and maintained on — computers located
          outside of Your state, province, country or other governmental jurisdiction where the data
          protection laws may differ from those from Your jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of such information
          represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that Your data is treated
          securely and in accordance with this Privacy Policy and no transfer of Your Personal Data
          will take place to an organization or a country unless there are adequate controls in
          place including the security of Your data and other personal information.
        </p>
        <br />
        <p>
          <strong>Disclosure of Your Personal Data</strong>
        </p>
        <br />
        <p>
          <strong>Business Transactions</strong>
        </p>
        <p>
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may
          be transferred. We will provide notice before Your Personal Data is transferred and
          becomes subject to a different Privacy Policy.
        </p>
        <br />
        <p>
          <strong>Law enforcement</strong>
        </p>
        <p>
          Under certain circumstances, the Company may be required to disclose Your Personal Data if
          required to do so by law or in response to valid requests by public authorities (e.g. a
          court or a government agency).
        </p>
        <br />
        <p>
          <strong>Other legal requirements</strong>
        </p>
        <p>
          The Company may disclose Your Personal Data in the good faith belief that such action is
          necessary to:
        </p>
        <ul className='list-disc ml-6'>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of Users of the Service or the public</li>
          <li>Protect against legal liability</li>
          <li>Security of Your Personal Data</li>
          <li>
            The security of Your Personal Data is important to Us, but remember that no method of
            transmission over the Internet, or method of electronic storage is 100% secure. While We
            strive to use commercially acceptable means to protect Your Personal Data, We cannot
            guarantee its absolute security
          </li>
        </ul>
        <br />
        <p>
          <strong>Detailed Information on the Processing of Your Personal Data</strong>
        </p>
        <p>
          The Service Providers We use may have access to Your Personal Data. These third-party
          vendors collect, store, use, process and transfer information about Your activity on Our
          Service in accordance with their Privacy Policies.
        </p>
        <br />
        <p>
          <strong>Analytics</strong>
        </p>
        <p>
          We may use third-party Service providers to monitor and analyze the use of our Service.
        </p>
        <br />
        <p>
          <strong>Email and or Message Marketing</strong>
        </p>
        <p>
          We may use Your Personal Data to contact You with newsletters, marketing or promotional
          materials and other information that may be of interest to You. You may opt-out of
          receiving any, or all, of these communications from Us by following the unsubscribe link
          or instructions provided in any email or message We send or by contacting Us.
        </p>
        <br />
        <p>
          <strong>Payments</strong>
        </p>
        <p>
          We may provide paid products and/or services within the Service. In that case, we may use
          third-party services for payment processing (e.g. payment gateways). We will not store or
          collect Your payment card details. That information is provided directly to Our
          third-party payment processors whose use of Your personal information is governed by their
          Privacy Policy.
        </p>
        <h2 className='text-xl'>
          GDPR <strong>Privacy Policy</strong>
        </h2>
        <p>Legal Basis for Processing Personal Data under GDPR</p>
        <p>We may process Personal Data under the following conditions:</p>
        <ul className='list-disc ml-6'>
          <li>
            <strong>Consent</strong>: You have given Your consent for processing Personal Data for
            one or more specific purposes.
          </li>
          <li>
            <strong>Performance of a contract</strong>: Provision of Personal Data is necessary for
            the performance of an agreement with You and/or for any pre-contractual obligations
            thereof.
          </li>
          <li>
            <strong>Legal obligations</strong>: Processing Personal Data is necessary for compliance
            with a legal obligation to which the Company is subject.
          </li>
          <li>
            <strong>Vital interests</strong>: Processing Personal Data is necessary in order to
            protect Your vital interests or of another natural person.
          </li>
          <li>
            <strong>Public interests</strong>: Processing Personal Data is related to a task that is
            carried out in the public interest or in the exercise of official authority vested in
            the Company.
          </li>
          <li>
            <strong>Legitimate interests</strong>: Processing Personal Data is necessary for the
            purposes of the legitimate interests pursued by the Company.
          </li>
        </ul>
        <p>
          In any case, the Company will gladly help to clarify the specific legal basis that applies
          to the processing, and in particular whether the provision of Personal Data is a statutory
          or contractual requirement, or a requirement necessary to enter into a contract.
        </p>
        <br />
        <p>
          <strong>Your Rights under the GDPR</strong>
        </p>
        <p>
          The Company undertakes to respect the confidentiality of Your Personal Data and to
          guarantee You can exercise Your rights.
        </p>
        <p>You have the right under this Privacy Policy, to:</p>
        <ul className='list-disc ml-6'>
          <li>
            <strong>Request access to Your Personal Data</strong>. The right to access, update or
            delete the information We have on You. Whenever made possible, you can access, update or
            request deletion of Your Personal Data directly within Your account settings section. If
            you are unable to perform these actions yourself, please contact Us to assist You. This
            also enables You to receive a copy of the Personal Data We hold about You.
          </li>
          <li>
            <strong>Request correction of the Personal Data that We hold about You</strong>. You
            have the right to have any incomplete or inaccurate information We hold about You
            corrected.
          </li>
          <li>
            <strong>Object to processing of Your Personal Data</strong>. This right exists where We
            are relying on a legitimate interest as the legal basis for Our processing and there is
            something about Your particular situation, which makes You want to object to our
            processing of Your Personal Data on this ground. You also have the right to object where
            We are processing Your Personal Data for direct marketing purposes.
          </li>
          <li>
            <strong>Request erasure of Your Personal Data</strong>. You have the right to ask Us to
            delete or remove Personal Data when there is no good reason for Us to continue
            processing it.
          </li>
          <li>
            <strong>Request the transfer of Your Personal Data</strong>. We will provide to You, or
            to a third-party You have chosen, Your Personal Data in a structured, commonly used,
            machine-readable format. Please note that this right only applies to automated
            information which You initially provided consent for Us to use or where We used the
            information to perform a contract with You.
          </li>
          <li>
            <strong>Withdraw Your consent</strong>. You have the right to withdraw Your consent on
            using your Personal Data. If You withdraw Your consent, We may not be able to provide
            You with access to certain specific functionalities of the Service.
          </li>
        </ul>
        <br />
        <p>
          <strong>Exercising of Your GDPR Data Protection Rights</strong>
        </p>
        <p>
          You may exercise Your rights of access, rectification, cancellation and opposition by
          contacting Us. Please note that we may ask You to verify Your identity before responding
          to such requests. If You make a request, We will try our best to respond to You as soon as
          possible.
        </p>
        <p>
          You may exercise these rights by requesting via email to out appointed Data Protection
          Officer Mr. Farid Romadhana at{' '}
          <a href='mailto:far@kar-tini.com' className='font-semibold text-blue-600'>
            far@kar-tini.com
          </a>
        </p>
        <br />
        <p>
          <strong>Categories of Personal Information Collected</strong>
        </p>
        <p>
          We collect information that identifies, relates to, describes, references, is capable of
          being associated with, or could reasonably be linked, directly or indirectly, with a
          particular Consumer or Device.
        </p>
        <br />
        <p>
          <strong>Category A: Identifiers.</strong>
        </p>
        <p>
          Examples: A real name, alias, postal address, unique personal identifier, online
          identifier, Internet Protocol address, email address, account name, driver’s license
          number, passport number, or other similar identifiers.
        </p>
        <p>Collected: Yes.</p>
        <br />
        <p>
          <strong>Category B: Commercial information.</strong>
        </p>
        <p>Examples: Records and history of products or services purchased or considered.</p>
        <p>Collected: Yes.</p>
        <br />
        <p>
          <strong>Category C: Biometric information.</strong>
        </p>
        <p>
          Examples: Genetic, physiological, behavioral, and biological characteristics, or activity
          patterns used to extract a template or other identifier or identifying information, such
          as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
          other physical patterns, and sleep, health, or exercise data.
        </p>
        <p>Collected: No.</p>
        <br />
        <p>
          <strong>Category D: Internet or other similar network activity.</strong>
        </p>
        <p>Examples: Interaction with our Service or advertisement.</p>
        <p>Collected: Yes.</p>
        <br />
        <p>
          <strong>Category E: Geolocation data.</strong>
        </p>
        <p>Examples: Approximate physical location.</p>
        <p>Collected: Yes.</p>
        <br />
        <p>
          <strong>Category F: Sensory data.</strong>
        </p>
        <p>Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</p>
        <p>Collected: No.</p>
        <br />
        <p>
          <strong>Category G: Professional or employment-related information.</strong>
        </p>
        <p>Examples: Current or past job history or performance evaluations.</p>
        <p>Collected: No.</p>
        <br />
        <p>
          <strong>Category H: Inferences drawn from other personal information.</strong>
        </p>
        <p>
          Examples: Profile reflecting a person’s preferences, characteristics, psychological
          trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
        </p>
        <p>Collected: Yes</p>
        <br />
        <p>
          <strong>Sources of Personal Information</strong>
        </p>
        <p>
          We obtain the categories of personal information listed above from the following
          categories of sources:
        </p>
        <ul className='list-disc ml-6'>
          <li>
            <strong>Directly from You.</strong> For example, from the forms You complete on our
            Service, preferences You express or provide through our Service, or from Your purchases
            on our Service.
          </li>
          <li>
            <strong>Indirectly from You.</strong> For example, from observing Your activity on our
            Service.
          </li>
          <li>
            <strong>Automatically from You.</strong> For example, through cookies We or our Service
            Providers set on Your Device as You navigate through our Service.
          </li>
          <li>
            <strong>From Service Providers.</strong> For example, third-party vendors to monitor and
            analyze the use of our Service, third-party vendors for payment processing, or other
            third-party vendors that We use to provide the Service to You.
          </li>
        </ul>
        <br />
        <p>
          <strong>Use of Personal Information for Business Purposes or Commercial Purposes</strong>
        </p>
        <p>
          We may use or disclose personal information We collect for “business purposes” or
          “commercial purposes”, which may include the following examples:
        </p>
        <ul className='list-disc ml-6'>
          <li>To operate our Service and provide You with our Service.</li>
          <li>
            To provide You with support and to respond to Your inquiries, including to investigate
            and address Your concerns and monitor and improve our Service.
          </li>
          <li>
            To fulfill or meet the reason You provided the information. For example, if You share
            Your contact information to ask a question about our Service, We will use that personal
            information to respond to Your inquiry. If You provide Your personal information to
            purchase a product or service, We will use that information to process Your payment and
            facilitate delivery.
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable law, court order,
            or governmental regulations.
          </li>
          <li>As described to You when collecting Your personal information</li>
          <li>For internal administrative and auditing purposes.</li>
          <li>
            To detect security incidents and protect against malicious, deceptive, fraudulent or
            illegal activity, including, when necessary, to prosecute those responsible for such
            activities.
          </li>
          <li>
            Please note that the examples provided above are illustrative and not intended to be
            exhaustive. For more details on how we use this information, please refer to the “Use of
            Your Personal Data” section.
          </li>
        </ul>
        <p>
          If We decide to collect additional categories of personal information or use the personal
          information We collected for materially different, unrelated, or incompatible purposes We
          will update this Privacy Policy.
        </p>
        <br />
        <p>
          <strong>Sale of Personal Information</strong>
        </p>
        <p>
          As defined, the “sell” and “sale” mean selling, renting, releasing, disclosing,
          disseminating, making available, transferring, or otherwise communicating orally, in
          writing, or by electronic or other means, a consumer’s personal information by the
          business to a third party for valuable consideration. This means that We may have received
          some kind of benefit in return for sharing personal information, but not necessarily a
          monetary benefit.
        </p>
        <p>
          This does not mean that all examples of that category of personal information were in fact
          sold, but reflects our good faith belief to the best of our knowledge that some of that
          information from the applicable category may be and may have been shared for value in
          return.
        </p>
        <br />
        <p>
          <strong>Share of Personal Information</strong>
        </p>
        <p>
          We may share Your personal information identified in the above categories with the
          following categories of third parties:
        </p>
        <ul className='list-disc ml-6'>
          <li>Service Providers</li>
          <li>Payment processors</li>
          <li>Our Client</li>
          <li>Our affiliates</li>
          <li>Our business partners</li>
          <li>
            Third party vendors to whom You or Your agents authorize Us to disclose Your personal
            information in connection with products or services We provide to You
          </li>
        </ul>
        <br />
        <p>
          <strong>Sale of Personal Information of Minors Under 16 Years of Age</strong>
        </p>
        <p>
          We do not knowingly collect personal information from minors under the age of 16 through
          our Service, although certain third party websites that we link to may do so. These
          third-party websites have their own terms of use and privacy policies and we encourage
          parents and legal guardians to monitor their children’s Internet usage and instruct their
          children to never provide information on other websites without their permission.
        </p>
        <p>
          We do not sell the personal information of Consumers We actually know are less than 16
          years of age, unless We receive affirmative authorization (the “right to opt-in”) from
          either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a
          Consumer less than 13 years of age. Consumers who opt-in to the sale of personal
          information may opt-out of future sales at any time. To exercise the right to opt-out, You
          (or Your authorized representative) may submit a request to Us by contacting Us.
        </p>
        <p>
          If You have reason to believe that a child under the age of 13 (or 16) has provided Us
          with personal information, please contact Us with sufficient detail to enable Us to delete
          that information.
        </p>
        <br />
        <p>
          <strong>Children’s Privacy</strong>
        </p>
        <p>
          Our Service does not address anyone under the age of 13. We do not knowingly collect
          personally identifiable information from anyone under the age of 13. If You are a parent
          or guardian and You are aware that Your child has provided Us with Personal Data, please
          contact Us. If We become aware that We have collected Personal Data from anyone under the
          age of 13 without verification of parental consent, We take steps to remove that
          information from Our servers.
        </p>
        <p>
          If We need to rely on consent as a legal basis for processing Your information and Your
          country requires consent from a parent, We may require Your parent’s consent before We
          collect and use that information.
        </p>
        <br />
        <p>
          <strong>Links to Other Websites</strong>
        </p>
        <p>
          Our Service may contain links to other websites that are not operated by Us. If You click
          on a third party link, You will be directed to that third party’s site. We strongly advise
          You to review the Privacy Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content, privacy policies or
          practices of any third party sites or services.
        </p>
        <br />
        <p>
          <strong>Changes to this Privacy Policy</strong>
        </p>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You of any changes by
          posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let You know via email and/or a prominent notice on Our Service, prior to the
          change becoming effective and update the “Last updated” date at the top of this Privacy
          Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any changes. Changes to
          this Privacy Policy are effective when they are posted on this page.
        </p>
        <br />
        <p>
          <strong>Contact Us</strong>
        </p>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <p>
          By visiting this page on our website:
          <a href='/' className='font-semibold text-blue-600'>
            {' '}
            www.kar-tini.com
          </a>
        </p>
        <p>
          By sending us an email:{' '}
          <a href='mailto:admin@kar-tini.com' className='font-semibold text-blue-600'>
            admin@kar-tini.com
          </a>
        </p>
      </div>
    </DefaultLayout>
  );
};

export default PrivacyPolicy;
