import React from "react";
import usersIcon from "../../assets/icon/users.svg";
import percentIcon from "../../assets/icon/percent.svg";
import buildingIcon from "../../assets/icon/building.svg";
import Translate from "../Animation/Translate";

const data = [
  { icon: percentIcon, count: 100, unit: "k", label: "Promotions per month", duration: 0.2 },
  { icon: usersIcon, count: 3.5, unit: "mill", label: "Customer Engaged", duration: 0.4 },
  {
    icon: buildingIcon,
    count: 80,
    unit: "k",
    label: "Outlets covered across Indonesia",
    duration: 0.6,
  },
];

const StatisticCounter = () => {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-soft-red-50 px-3 md:px-0">
      <div className="grid grid-cols-1 md:grid-cols-3 py-12 gap-y-7 md:gap-y-0 max-w-5xl mx-auto">
        {data.map((item, index) => (
          <div key={index} className="flex flex-col items-center gap-3">
            <Translate duration={item.duration} from="bottom">
              <div className="rounded-full text-center p-4 flex items-center justify-center bg-gradient-to-br from-blue-600 via-purple-600 to-soft-red-600">
                <img src={item.icon} alt="users icon" className="w-10" />
              </div>
            </Translate>
            <Translate duration={item.duration + 0.1} from="bottom">
              <div className="text-center">
                <p className="text-xl md:text-4xl font-bold bg-gradient-to-br from-blue-600 via-purple-600 to-soft-red-600 bg-clip-text text-transparent">
                  {`${item.count}${item.unit}+`}
                </p>
                <p className="text-transparent-black-60 text-xs">{item.label}</p>
              </div>
            </Translate>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticCounter;
