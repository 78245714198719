import React from 'react';
import Logo from '../../assets/images/logo.png';

const NotFound = () => {
  return (
    <div className='min-h-screen text-dark flex flex-col gap-2 items-center justify-center'>
      <img src={Logo} alt='Logo' className='w-44' />
      <h1 className='font-bold text-6xl'>404</h1>
      <p className='text-monochrome-400'>Sorry, this page does’nt exist or a client side error occured</p>
      <a href='/' className='text-primary-base text-sm'>
        Back to Home
      </a>
    </div>
  );
};

export default NotFound;
