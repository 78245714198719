import React, { useState } from "react";

const featureData = [
  { id: 1, label: "Whatsapp Features" },
  { id: 2, label: "Promo Budget Management" },
  { id: 3, label: "Receipt Inteligence" },
  { id: 4, label: "Display Inteligence" },
  { id: 5, label: "Conversational AI" },
];

const Features = () => {
  const [selectedFeature, setSelectedFeature] = useState(featureData[0]);
  return (
    <div className="max-w-6xl mx-auto py-12">
      <div className="px-10">
        <h2 className="text-xl md:text-4xl font-semibold text-center">
          Rediscover powerful ways to deliver consumer and trade promotions
        </h2>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-10 items-center my-10">
        <div>
          {featureData.map((item, index) => (
            <div
              className="flex items-center gap-3 cursor-pointer"
              key={item.id}
              onClick={() => setSelectedFeature(item)}
            >
              <div
                className={`w-1 h-10 rounded-none first:rounded-t-md last:rounded-b-md ${
                  selectedFeature.id === item.id ? "bg-blue-600" : "bg-monochrome-200"
                } `}
              ></div>
              <p
                className={`${
                  selectedFeature.id === item.id
                    ? "bg-gradient-to-r from-purple-600 via-blue-600 to-soft-red-500 text-transparent bg-clip-text"
                    : "text-monochrome-300"
                }  font-semibold`}
              >
                {item.label}
              </p>
            </div>
          ))}
        </div>
        <div className="bg-gradient-to-br from-blue-50 to-soft-red-100 rounded-xl w-full md:w-64 h-[30rem] justify-self-center"></div>
        <div className="flex flex-col gap-5 md:text-start text-center md:col-span-1 col-span-2 px-4 md:px-0">
          <p className="md:text-3xl text-lg leading-tight font-semibold">
            Simplify the journey & experience for your shoppers
          </p>
          <p className="text-monochrome-400 md:text-sm text-xs">
            Provide a seamless way for your consumers to join your promotions by using WhatsApp
            conversations. Our WhatsApp features will make it so easy so that even a clueless tech
            person can join.
          </p>

          <div className="flex justify-center">
            <button className="border border-purple-600 py-2 px-4 rounded-xl flex items-center gap-2">
              <p className="bg-gradient-to-r from-blue-600 from-5% via-purple-600 via-50% to-soft-red-500 to-95% text-transparent bg-clip-text font-semibold">
                {`Explore ${selectedFeature.label}`}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
