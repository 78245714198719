import React from 'react';
import arrow from '../../assets/icon/arrow.svg';
import { motion } from 'framer-motion';

const Button = () => {
  return (
    <>
      <motion.button
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        className='bg-gradient-to-br from-blue-600 via-purple-600 to-soft-red-600 transition-colors py-3 px-4 rounded-xl flex items-center gap-2 text-monochrome-0 border-2 border-transparent-white-60'
      >
        Request a Demo
        <img src={arrow} alt='arrow' />
      </motion.button>
    </>
  );
};

export default Button;
