import './App.css';
import Achievement from './Components/Achievement';
import CaseStudies from './Components/CaseStudies';
import DefaultLayout from './Components/DefaultLayout';
import Features from './Components/Features';
import Hero from './Components/Hero';
import MonitorFeatures from './Components/MonitorFeatures';
import OurServices from './Components/OurServices';
import StatisticCounter from './Components/StatisticCounter';

function App() {
  return (
    <DefaultLayout>
      <Hero />
      <StatisticCounter />
      <Achievement />
      <OurServices />
      <Features />
      <MonitorFeatures />
      <CaseStudies />
    </DefaultLayout>
  );
}

export default App;
