import React from "react";
import Phone from "../../assets/images/phone-mockup.png";
import Button from "../Button";

const Hero = () => {
  return (
    <div className="text-center flex flex-col items-center gap-3 md:gap-5 pt-12 bg-hero">
      <div className="text-2xl md:text-5xl font-semibold px-3 md:px-0">
        <h1>Creating Conversations that Convert</h1>
        <h1>{'(we call it: "Convertsations")'}</h1>
        <h1 className="">
          for{" "}
          <span className="bg-gradient-to-r from-purple-600 to-soft-red-600 bg-clip-text text-transparent">
            Price Cut Promotions
          </span>
        </h1>
      </div>
      <div>
        <p className="text-monochrome-400">
          Creating hygiene business growth through game-changing <br />
          solutions for FMCG consumer & trade promotions
        </p>
      </div>
      <div className="my-12">
        <Button />
      </div>
      <img src={Phone} alt="Phone mockup" className="w-2/3 md:w-1/3" />
    </div>
  );
};

export default Hero;
